import React, { useState } from 'react';
import './index.scss';
import emailImg from '../../../../../../assets/icons/1.png';
import telefonoImg from '../../../../../../assets/icons/5.png';
import passImg from '../../../../../../assets/icons/2.png';
import user from '../../../../../../assets/icons/3.png';
import terminos from '../../../../../../assets/Files/terminos.pdf';


import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const Registro = ({ setIsAuth, invitado, setRegister }) => {
    const [credenciales, setCredenciales] = useState({
        name: "",
        email: "",
        phone: "",
        pass: "",
        confirmPass: "",
    });

    const [progress, setProgress] = useState(false)
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false)
    const [failedMessage, setFailedMessage] = useState('')
    const { name, email, phone, pass, confirmPass } = credenciales;

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (name.trim() === '' || email.trim() === '' || pass.trim() === '' || confirmPass.trim() === '' || phone.trim() === '') {
            setFailedMessage('Todos los campos son obligatorios.')
            setFailedLoginSnackbar(true)
            return;
        }

        if (pass.trim() !== confirmPass.trim()) {
            setFailedMessage('Las contraseñas no coinciden.')
            setFailedLoginSnackbar(true)
            return;
        }

        if (pass.length < 8) {
            setFailedMessage('La contraseña debe tener al menos 8 caracteres.')
            setFailedLoginSnackbar(true)
            return;
        }

        if (phone.length !== 8) {
            setFailedMessage('El telefono debe tener 8 caracteres.')
            setFailedLoginSnackbar(true)
            return;
        }

        setProgress(true)
        const data = {
            name: name,
            email: email,
            phone: phone,
            password: pass,
            password2: confirmPass
        }

        var url = "";

        if (invitado !== null) {
            //url = 'http://127.0.0.1:8000/api/register_user_invitation/'+invitado+'/'
            url = 'https://referidos-app.herokuapp.com/api/register_user_invitation/'+invitado+'/'
        }

        else {
            //url = 'http://127.0.0.1:8000/api/register_user/'
            url ='https://referidos-app.herokuapp.com/api/register_user/'
        }

        fetch (url, {
            method: "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.response) {

                const {token} = res;
                localStorage.setItem('auth-token', token);
                localStorage.setItem('user', JSON.stringify(data))
                window.gtag('event', 'conversion', {'send_to': 'AW-781299259/dIJnCN60v6kDELvcxvQC'});
                setProgress(true)
                setFailedLoginSnackbar(false)
                setIsAuth(true);

            } 
            else { 
                if (res.email){
                    setFailedMessage('Error de registro. Asegúrese de que el email ingresado sea válido y único.')
                }
                setProgress(false)
                setFailedLoginSnackbar(true)
            }
        })
    };

    return (
        <div className="auth-form-container">
            <Snackbar 
                open={failedLoginSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error">
                    {failedMessage}
                </Alert>
            </Snackbar>
            <p className="ingresar-p">Nuevo registro</p>
            <form onSubmit={handleSubmit}>
                <div className="input-login">
                    <span><img src={user} alt="email" className="pass-img" /></span>
                    <input type="text" name="name" value={name} onChange={e => handleChange(e)} placeholder="Nombre y apellido" />
                </div>
                <div className="input-login">
                    <span><img src={emailImg} alt="email" className="email-img" /></span>
                    <input type="text" name="email" value={email} onChange={e => handleChange(e)} placeholder="Email" />
                </div>
                <div className="input-login">
                    <span><img src={telefonoImg} alt="phone" className="phone-img" /></span>
                    <input type="number" name="phone" value={phone} onChange={e => handleChange(e)} placeholder="Telefono" />
                </div>
                <div className="input-login">
                    <span><img src={passImg} alt="pass" className="pass-img" /></span>
                    <input type="password" name="pass" value={pass} onChange={e => handleChange(e)} placeholder="Contraseña" />
                </div>
                <div className="input-login">
                    <span><img src={passImg} alt="pass" className="pass-img" /></span>
                    <input type="password" name="confirmPass" value={confirmPass} onChange={e => handleChange(e)} placeholder="Repetir contraseña" />
                </div>

                <div className="linea-register" />

                <p className="terminos-p">Al hacer click en REGISTRAR, aceptas los <br /> <a href={terminos} target='_blank'>Términos y condiciones</a>.</p>
                {!progress ?
                    <div className="form-button" onClick={handleSubmit}><span className="fontSpace">REGISTRAR</span></div>:
                    <CircularProgress color="primary"/> 
                }
            </form>
            <div className="backToLogin">
                <p>¿Ya tienes cuenta?</p>
                <div className="form-button" onClick={() => setRegister(false)}><span className="fontSpace">INICIA SESIÓN</span></div>
            </div>
        </div>
    );
}
 
export default Registro;