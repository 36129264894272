import React from 'react'
import { GoogleLogin } from 'react-google-login';
import google from '../../../../../../assets/icons/google.png';

const CLIENT_ID = '68078207893-28ja537of84mnon61pcj8v5mg9nm1d1c.apps.googleusercontent.com';


const GoogleBtn = ({setIsAuth, invitado}) => {
  
  

  const login = async (response) => {

    if(response.accessToken){
    
        const data = {
          name: response.profileObj.name,
          email: response.profileObj.email,
          password: response.profileObj.googleId,
          password2: response.profileObj.googleId
        }
        
        var url = "";

        if (invitado !== null) {
            //url = 'http://127.0.0.1:8000/api/google_sign_in_invitation/'+invitado+'/'
            url = 'https://referidos-app.herokuapp.com/api/google_sign_in_invitation/'+invitado+'/'
        } else {
            //url = 'http://127.0.0.1:8000/api/google_sign_in/'
            url = 'https://referidos-app.herokuapp.com/api/google_sign_in/'
        }

        fetch (url, {
            method: "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.response) {
                localStorage.setItem('auth-token', res.token);
                setIsAuth(true);
            } 
            else { 
            }
        })


    } 
  }

  const handleLoginFailure = (response) => {

  }


  
  return (
    <div>
        <GoogleLogin
            clientId={CLIENT_ID}
            render={(renderProps) => (
                <button onClick={renderProps.onClick} className="ingresar-google">
                    <img src={google} alt="google"/>
                    <span className="fontSpace">Ingresar con Google</span>
                </button>
            )}
            onSuccess={ login }
            onFailure={handleLoginFailure}
            cookiePolicy={ 'single_host_origin' }
            responseType='code,token'
        />    
    </div>
  )
  
}

export default  GoogleBtn;