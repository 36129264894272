import React, { useState } from 'react';
import './index.scss';
import emailImg from '../../../../../../assets/icons/1.png';
import passImg from '../../../../../../assets/icons/2.png';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalC from '../../../../../Private/components/Modal';
import Modal from 'react-modal';
import GoogleBtn from '../Login/googleBtn';


const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  }
};

const Login = ({ setRegister, setIsAuth, modal, setModal, invitado }) => {
    const [credenciales, setCredenciales] = useState({
        email: "",
        pass: "",
    });

    const [progress, setProgress] = useState(false)
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false)
    const {email, pass } = credenciales;
    const [selecModal, setSelecModal] = useState("");
    const [modalIsOpen,setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (email.trim() === '' || pass.trim() === '') {
            return;
        }

        setProgress(true)
        const data = {
            username: email,
            password: pass
        }

        //var url = 'http://127.0.0.1:8000/api/login/'
        var url ='https://referidos-app.herokuapp.com/api/login/'
        fetch (url, {
            method: "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.token) {
                const {token} = res;
                localStorage.setItem('auth-token', token);
                setProgress(true)
                setFailedLoginSnackbar(false)
                setIsAuth(true);

            } 
            else { 
                setProgress(false)
                setFailedLoginSnackbar(true)
            }
        
        })

        

    };



    return (
        
        <div>
            { modal ? 
                <ModalC setModal={setModal} selecModal={selecModal} />
            :
        
                <div className="auth-form-container">
                    <Snackbar 
                        open={failedLoginSnackbar} 
                        autoHideDuration={6000} 
                        onClose={handleClose}
                    >
                        <Alert 
                            onClose={handleClose} 
                            severity="error">
                            Correo electrónico y/o contraseña incorrectos
                        </Alert>
                    </Snackbar>
                    <p className="ingresar-p fontSpace">COMIENZA AHORA</p>
                    <form >
                        <div className="form-button" onClick={() => setRegister(true)}><span className="fontSpace">REGÍSTRATE AQUÍ</span></div>
                        <div className="input-login">
                            <span><img src={emailImg} alt="email" className="email-img" /></span>
                            <input type="text" name="email" value={email} onChange={e => handleChange(e)} placeholder="Email" />
                        </div>
                        <div className="input-login">
                            <span><img src={passImg} alt="pass" className="pass-img" /></span>
                            <input type="password" name="pass" value={pass} onChange={e => handleChange(e)} placeholder="Contraseña" />
                        </div>
                        {!progress ?
                            <div>
                                <div className="form-button" onClick={handleSubmit}><span className="fontSpace">Ingresa al portal</span></div>
                                <GoogleBtn setIsAuth={setIsAuth} invitado={invitado}></GoogleBtn>
                            </div>:
                            
                            <CircularProgress color="primary"/>  
                        }
     
                        <div className="linea-gris" />
                        <a href="#pass" className="form-button" onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            } else {
                                setModal(true);
                            }
                            setSelecModal("email");
                        }}>¿Olvidaste tu contraseña?</a>
                    </form>
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <ModalC setModal={setModal} selecModal={selecModal} closeModal={closeModal} />
                    </Modal>
                </div>
            }
        </div>
    );
}
 
export default Login;