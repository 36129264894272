import React, { Fragment } from 'react';
import * as clipboard from "clipboard-polyfill/text";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import compartirImg from '../../../../../../assets/icons/8.png';
import facebookImg from '../../../../../../assets/icons/f.png';
import whatsappImg from '../../../../../../assets/icons/w.png';
import './index.scss';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Generar = () => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    const copyToClipboard = async e => {
        e.preventDefault();
        const textField = document.querySelector('.compartir-link').innerHTML;
        await clipboard.writeText(textField);
    }

    const link = "https://presentadores.panoramachile.cl//?query="+localStorage.getItem('auth-token'); //LINK INVITACION CON TOKEN DE PADRINO
    //const link = "http://localhost:3000//?query="+localStorage.getItem('auth-token'); //LINK INVITACION CON TOKEN DE PADRINO

    return (
        <Fragment>
            <p className="ingresar-p"><span className="fontSpace">Generar invitación</span></p>
            <form className="generar-form">
                <p className="comparte-p">Comparte el siguiente link para invitar amigos y conocidos <br /> al programa de presentadores.</p>

                <a target="_blank" rel="noopener noreferrer" href={link} className="compartir-link">{link}</a>

                <a target="_blank" rel="noopener noreferrer" href={`http://www.facebook.com/sharer.php?u=${link}`} className="compartir-container">
                    <div>
                        <img src={compartirImg} alt="compartir" className="compartir-img" />
                        <img src={facebookImg} alt="facebook" className="compartir-red" />
                    </div>
                    <p>Compartir en Facebook</p>
                </a>

                <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=Utiliza mi invitación para ganar $60.000 refiriendo: ${link}`} className="compartir-container">
                    <div>
                        <img src={compartirImg} alt="compartir" className="compartir-img" />
                        <img src={whatsappImg} alt="whatsapp" className="compartir-red" />
                    </div>
                    <p>Compartir en Whatsapp</p>
                </a>

                <button className="copiar" onClick={e => {
                    copyToClipboard(e);
                    handleClick();
                }}><span className="fontSpace">COPIAR EN EL PORTAPAPELES</span></button>
                <Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Enlace copiado al portapapeles
                    </Alert>
                </Snackbar>
            </form>
        </Fragment>
    );
}
 
export default Generar;