import React from 'react';
import './index.scss';

const Boton = ({ text, nueva, fun }) => {
    return (
        <div className={`boton${nueva}`} onClick={fun}>
            <div className="boton-texto fontSpace">
                {text}
            </div>
            <img src="https://www.panoramachile.cl/images/iconos/arrow_der.png" alt="flecha"/>
        </div>
    );
}
 
export default Boton;