
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import RouterApp from '../../../index'

export default function App() {
    return (
      <Router>
          <Switch>
            <Route 
              path="/admin" 
              component={() => { 
                  window.location.href = 'https://referidos-app.herokuapp.com/admin'; 
                  //window.location.href = 'http://127.0.0.1:8000/admin'; 
                  return null;
                }
              }
            />
            <Route path="/" component={RouterApp}/>
          </Switch>
      </Router>
    );
  
}