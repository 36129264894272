import React from 'react';
import './index.scss';
import equis from '../../../../assets/icons/7.png';
import Datos from './components/Datos';
import Generar from './components/Generar';
import NuevaReferencia from './components/Nueva';
import Eficaces from './components/Eficaces';
import Contraseña from './components/Contraseña';
import Email from './components/Email';

const Modal = ({ setModal, selecModal, closeModal }) => {

    return (
        <div className="modal-container">
            { selecModal !== "email" && <img src={equis} alt="equis" className="equis" onClick={() => {
                setModal(false);
                if (window.innerWidth > 992) closeModal();
            }}/>}

            <div className="modal-innerContainer">
                { selecModal === "nueva" &&
                    <NuevaReferencia closeModal={closeModal}/>
                }
                { selecModal === "generar" && 
                    <Generar />
                }
                { selecModal === "datos" &&
                    <Datos closeModal={closeModal} setModal={setModal} />
                }
                { selecModal === "contraseña" &&
                    <Contraseña closeModal={closeModal} />
                }
                { selecModal === "email" &&
                    <Email closeModal={closeModal} />
                }
            </div>
            { selecModal === "eficaces" &&
                <Eficaces setModal={setModal} />
            }
        </div>
    );
}
 
export default Modal;