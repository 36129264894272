import React, { Fragment, useState } from 'react';
import Header from '../Layout/Header';
import Bienvenido from './components/Bienvenido';
import Tablas from './components/Tablas';
import './index.scss';

const Private = ({ selecModal, setSelecModal }) => {
    const [modal, setModal] = useState(false);

    return (
        <Fragment>
            <Header modal={modal} setModal={setModal} selecModal={selecModal} setSelecModal={setSelecModal}/>
            <div className="private-container">
                <Bienvenido modal={modal} setModal={setModal} selecModal={selecModal} setSelecModal={setSelecModal} />
                { !modal && <Tablas /> }
            </div>
        </Fragment>
    );
}
 
export default Private;