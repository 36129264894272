import React from 'react';
import './index.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import video1 from '../../../../../../assets/Videos/Funcionamiento.mp4';
import video2 from '../../../../../../assets/Videos/videoActualizado.mp4';
import img1 from '../../../../../../assets/Carrusel/252842 JPG.jpg';
import img2 from '../../../../../../assets/Carrusel/FOTOS PANORAMA _1.jpg';
import img3 from '../../../../../../assets/Carrusel/FOTOS PANORAMA _12CC.jpg';
import img4 from '../../../../../../assets/Carrusel/PHOTO-2020-08-24-19-18-46.jpg';
import img5 from '../../../../../../assets/Carrusel/Quincho.jpg';
import equis from '../../../../../../assets/icons/7.png';
import terminos from '../../../../../../assets/Files/terminos.pdf';
import Modal from 'react-modal';

const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
    }
};
   
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const Eficaces = ({ setModal }) => {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    
    function openModal() {
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    return (
        <div className="eficaces-container">
            <p className="eficaz-p"><span className="recom-verde">¿CÓMO GENERAR REFERENCIAS </span>
            <span className="recom-gris">MÁS EFICACES?</span></p>

            <div className="linea-amarilla"/>

            <div className="eficaces-innerContainer">
                <div className="focalite-container">
                    <p className="titulo-p fontSpace">Focalízate en nuestro { window.innerWidth < 992 && <br />} grupo de interés:</p>

                    <p className="parrafo-p fontSpace">Las personas que presentan más interés por nuestro producto tienen entre 30 y 65 años y viven en departamentos o casas con terrazas y quinchos de las comunas de Las Condes, Lo Barnechea, Vitacura, Providencia, Ñuñoa, y Viña del Mar. ¡Nos instalan también en sus segundas viviendas!</p>
                </div>

                { window.innerWidth < 992 && <div className="linea-amarilla"/> }

                <div className="eficaces-bottom">
                    <div className="videos">
                        <p>CAPACÍTATE EN 3 MINUTOS</p>
                        <div className="videos-container">
                            <video className="video1" controls>
                                <source src={video1} type="video/mp4"　/>
                                Your browser does not support HTML video.
                            </video>

                            <video className="video2" controls>
                                <source src={video2} type="video/mp4"　/>
                                Your browser does not support HTML video.
                            </video>

                            { window.innerWidth < 992 && <div className="linea-amarilla"/> }
                        </div>
                    </div>

                    <div>
                        <p className="titulo-p">Tus premios:</p>
                        <ul>
                            <li><p>{ window.innerWidth > 992 && <span>.</span>}Si tu referido compra un cierre, ganas $60.000.</p></li>
                            <li><p>{ window.innerWidth > 992 && <span>.</span>}Además, si un referido del presentador que invistaste compra un cierre, tú también ganas $20.000.</p></li>
                        </ul>

                        <div style={{ cursor: "pointer" }} onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            }
                        }}>
                            <Carousel autoPlay>
                                <div>
                                    <img src={img1} alt="1" className="img-carousel" />
                                </div>
                                <div>
                                    <img src={img2} alt="2" className="img-carousel" />
                                </div>
                                <div>
                                    <img src={img3} alt="3" className="img-carousel" />
                                </div>
                                <div>
                                    <img src={img4} alt="4" className="img-carousel" />
                                </div>
                                <div>
                                    <img src={img5} alt="5" className="img-carousel" />
                                </div>
                            </Carousel>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal-carousel">
                            <img src={equis} alt="equis" className="equis" onClick={() => {
                                setModal(false);
                                closeModal();
                            }}/>
                            <Carousel autoPlay>
                                <div>
                                    <img src={img1} alt="1" className="img-carousel-modal" />
                                </div>
                                <div>
                                    <img src={img2} alt="2" className="img-carousel-modal" />
                                </div>
                                <div>
                                    <img src={img3} alt="3" className="img-carousel-modal" />
                                </div>
                                <div>
                                    <img src={img4} alt="4" className="img-carousel-modal" />
                                </div>
                                <div>
                                    <img src={img5} alt="5" className="img-carousel-modal" />
                                </div>
                            </Carousel>
                        </div>
                    </Modal>
                </div>

                <a href={terminos} target='_blank'>Revisa los Términos y Condiciones</a>
            </div>
        </div>
    );
}
 
export default Eficaces;