import React, { Fragment, useEffect, useState } from 'react';
import emailImg from '../../../../../../assets/icons/1.png';
import userImg from '../../../../../../assets/icons/3.png';
import telefonoImg from '../../../../../../assets/icons/5.png';
import ubicacionImg from '../../../../../../assets/icons/6.png';
import checkVerdeImg from '../../../../../../assets/icons/12.png';


import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const NuevaReferencia = (props) => {
    const [credenciales, setCredenciales] = useState({
        name: "",
        email: "",
        telefono: "",
        comuna: "",
    });

    const [progress, setProgress] = useState(false)
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false)
    const [failedMessage, setFailedMessage] = useState('')
    const { name, email, telefono, comuna } = credenciales;
    const [authToken, setAuthToken] = useState("");
    const [sendEmail, setSendEmail] = useState(false);


    useEffect(() => {
        setAuthToken(localStorage.getItem('auth-token'));
    }, []);

    const [exito, setExito] = useState(false);

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeChecked = e => {
        e.persist();
        setSendEmail(prevState => !prevState);
     };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (name.trim() === '' || email.trim() === '' || telefono.trim() === '' || comuna.trim() === '') {
            setFailedMessage('Todos los campos son obligatorios.')
            setFailedLoginSnackbar(true)
            return;
        }

        if (telefono.length !== 8) {
            setFailedMessage('Ingrese número de 8 dígitos.')
            setFailedLoginSnackbar(true)
            return;
        }

        setProgress(true)
        const data = {
            name: name,
            email: email,
            phone_number: telefono,
            comuna: comuna,
            token_presenter: authToken,
            send_email: sendEmail
        }


        //var url = 'http://127.0.0.1:8000/api/references/new_reference'
        var url = 'https://referidos-app.herokuapp.com/api/references/new_reference'

        fetch (url, {
            method: "POST",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
                "Authorization": `Token ${authToken}`
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.response) {
                window.gtag('event', 'conversion', {'send_to': 'AW-781299259/dIJnCN60v6kDELvcxvQC'});
                setProgress(false)
                setFailedLoginSnackbar(false)
                setExito(true);

            } 
            else { 
                if (res.email){
                    setFailedMessage('El email ingresado debe ser único')
                }
                if (res.phone_number){
                    setFailedMessage('El número de teléfono ingresado debe ser único')
                }
                setProgress(false)
                setFailedLoginSnackbar(true)
            }
        })


    };

    return (
        <Fragment>
            <Snackbar 
                open={failedLoginSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error">
                    {failedMessage}
                </Alert>
            </Snackbar>
            
            <p className="ingresar-p"><span className="fontSpace">Nueva referencia</span></p>
            { !exito ?
                <form onSubmit={handleSubmit}>
                    <div className="input-login">
                        <span><img src={userImg} alt="email" className="ubi-img" /></span>
                        <input type="text" name="name" value={name} onChange={e => handleChange(e)} placeholder="Nombre y apellido del referido" />
                    </div>
                    <div className="input-login">
                        <span><img src={emailImg} alt="pass" /></span>
                        <input type="text" name="email" value={email} onChange={e => handleChange(e)} placeholder="Email del referido" />
                    </div>
                    <div className="input-login">
                        <span><img src={telefonoImg} alt="pass" className="ubi-img" /></span>
                        <input type="text" pattern="[0-9]*" name="telefono" value={telefono} onChange={e => handleChange(e)} placeholder="Número telefónico del referido" />
                    </div>
                    <div className="input-login">
                        <span><img src={ubicacionImg} alt="pass" className="ubi-img" /></span>
                        <input type="text" name="comuna" value={comuna} onChange={e => handleChange(e)} placeholder="Comuna donde se instalará el producto" />
                    </div>

                    <div className="linea-gris" />

                    <p className="presentador-p">Recuerda comentarle a tu referido acerca del producto.</p>

                    <p className="presentador-p"><input className="input-correo-aviso" type="checkbox" value={sendEmail} onChange={e => handleChangeChecked(e)} />Enviar correo de aviso</p>
                    
                    
                    {!progress ?
                        <button className="ingresar"><span className="fontSpace">REGISTRAR REFERENCIA</span></button>:
                        <CircularProgress color="primary"/> 
                    }   
                </form>
            :
                <Fragment>
                    <div className="exito">
                        <img src={checkVerdeImg} alt="checkverde" className="check-verde"/>
                        <p>Referencia generada con éxito.</p>
                    </div>

                    <div className="cerrar-container"><button className="cerrar-btn" onClick={props.closeModal}><span className="fontSpace">CERRAR</span></button></div>
                </Fragment>

            }
        </Fragment>
    );
}
 
export default NuevaReferencia;