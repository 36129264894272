import React, { Fragment, useState, useEffect } from 'react';
import Boton from '../Boton';
import ModalC from '../Modal';
import './index.scss';
import Modal from 'react-modal';


 
const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  }
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const Bienvenido = ({ modal, setModal, selecModal, setSelecModal }) => {
    const [currentUser, setCurrentUser] = useState("");
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    useEffect(() => {
        //var url2 = 'http://127.0.0.1:8000/api/user_data/'
        var url2 = 'https://referidos-app.herokuapp.com/api/user_data/'

        fetch (url2, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json',
                "Authorization": `Token ${localStorage.getItem('auth-token')}`
            }
        }).then(response => response.json ())
        .then(res => {
            localStorage.setItem('user', JSON.stringify(res))
            setCurrentUser(JSON.parse(localStorage.getItem('user')))
        })
    },[])

    return (
        <div className="bienvenido-container">
            { modal ? 
                <ModalC setModal={setModal} selecModal={selecModal} />
            :
                <Fragment>
                    <p className="bienvenido-p">BIENVENIDO A <span>TU PORTAL</span></p>
                    <div className="linea-amarilla" />

                    <div className="nombre-presentador">
                        <p className="nombre-empresario">{currentUser.name}</p>
                        <p className="email-empresario">{currentUser.email}</p>
                    </div>
                    
                    <div className="botones">
                        <span onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            } else {
                                setModal(true);
                            }
                            setSelecModal("nueva");
                        }}><Boton text={<p>NUEVA REFERENCIA</p>} nueva=" nueva" /></span>
                        <span onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            } else {
                                setModal(true);
                            }
                            setSelecModal("generar");
                        }}><Boton text={<p>GENERAR INVITACIÓN</p>} nueva="" /></span>
                        <span onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            } else {
                                setModal(true);
                            }
                            setSelecModal("datos");
                        }}><Boton text={<p>DATOS BANCARIOS</p>} nueva="" /></span>
                        <span onClick={() => {
                            if (window.innerWidth > 992) {
                                openModal();
                            } else {
                                setModal(true);
                            }
                            setSelecModal("eficaces");
                        }}><Boton text={<p>¿CÓMO POTENCIAR <br /> TUS REFERENCIAS?</p>} nueva="" /></span>
                    </div>
                    
                    <Modal
                        isOpen={modalIsOpen}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <ModalC setModal={setModal} selecModal={selecModal} closeModal={closeModal} />
                    </Modal>
                </Fragment>
            }
        </div>
    );
}
 
export default Bienvenido;