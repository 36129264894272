import React, { useState, useEffect } from 'react';
import Private from './Private';
import Public from './Public';

const RouterApp = () => {
    const [isAuth, setIsAuth] = useState(false);
    const [selecModal, setSelecModal] = useState("");

    useEffect(() => {
        const uid = localStorage.getItem("auth-token");
        uid !== null && setIsAuth(true);
    }, [isAuth]);

    return (
        <main>
            {
                isAuth ? (
                    <Private selecModal={selecModal} setSelecModal={setSelecModal} />
                ) : (
                    <Public setIsAuth={setIsAuth} />
                )
            }
        </main>
    );
}
 
export default RouterApp;