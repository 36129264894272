import React, { useState, useEffect } from 'react';
import './index.scss';
import Login from './components/Login';
import Registro from './components/Registro';
import equis from '../../../../assets/icons/7.png';

const Autenticacion = ({registerClicked, setShowAuth, setIsAuth, invitado, modal, setModal}) => {
    const [register, setRegister] = useState(false);

    useEffect(() => {
        setRegister(registerClicked)
    }, [registerClicked]);

    return (
        <div className="auth-container">
            <img src={equis} alt="equis" className="equis" onClick={() => {
                setShowAuth(false);
                setModal(false);
            }}/>
            {
                register ? (
                    <Registro setIsAuth={setIsAuth} invitado={invitado} setRegister={setRegister} />
                ) : (
                    <Login setRegister={setRegister} setIsAuth={setIsAuth} modal={modal} setModal={setModal} invitado={invitado} />
                )
            }
        </div>
    );
}
 
export default Autenticacion;