import React, {Fragment, useEffect, useState} from 'react';
import './index.scss';
import ModalC from '../../Private/components/Modal';
import Modal from 'react-modal';
import logo from '../../../assets/icons/panoramalumonlogo.png';

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  }
};


const Header = ({ modal, setModal, selecModal, setSelecModal }) => {
    const [auth, setAuth] = useState();
    const [google, setGoogle] = useState(false)
    const [modalIsOpen,setIsOpen] = useState(false);

    useEffect(() => {
        setAuth(localStorage.getItem('auth-token'));
        var user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            if (user.google) {
                setGoogle(true)
            }
        }
    }, []);

    function openModal() {
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    const handleLogOut = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('auth-token')
    }

    return (
        <div>
            <header>
                <div className="header-left">
                    <a href="https://www.panoramachile.cl/">
                        <img src="https://www.panoramachile.cl/images/menu/menu-bar.svg"  alt="ham" />
                    </a>
                    
                    { window.innerWidth > 992 ?
                        
                        <p className="fontSpace" >INICIO PANORAMA</p>
                        
                    :
                        <Fragment>
                            {auth ? 
                                <a style={{ color: "grey", marginLeft: "5px" }} href="#pass" className="datosContacto fontSpace" onClick={() => {
                                    if (window.innerWidth > 992) {
                                        openModal();
                                    } else {
                                        setModal(true);
                                    }
                                    setSelecModal("contraseña");
                                }}>CAMBIAR CONTRASEÑA</a>
                            :
                                <p className="fontSpace">INICIO PANORAMA</p>
                            }
                        </Fragment>
                    }
                </div>

                <div className="header-center">
                    <img src={logo} alt="logo"/>
                </div>

                {auth ? 
                    <div className="header-right">
                        { !google ?
                            <a href="#pass" className="datosContacto fontSpace" onClick={() => {
                                if (window.innerWidth > 992) {
                                    openModal();
                                } else {
                                    setModal(true);
                                }
                                setSelecModal("contraseña");
                                }}>CAMBIAR CONTRASEÑA
                            </a>:
                            <div></div>
                        
                        }
                        
                        <a href="/" className="cotizar" onClick ={handleLogOut}>
                            <span className="fontSpace">CERRAR SESION</span>
                            <img src="https://www.panoramachile.cl/images/menu/click.svg" alt="cursor"/>
                        </a>
                    </div>:

                    <div className="header-right">
                        
                    </div>

                
                }
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ModalC setModal={setModal} selecModal={selecModal} closeModal={closeModal} />
                </Modal>
            </header>
        </div>
    );
}
 
export default Header;