import React, { useState, useEffect } from 'react';
import './index.scss';
import Header from '../Layout/Header';
import Autenticacion from './components/Autenticacion';
import ProductoEmpresa from './components/ProductoEmpresa';
import ComoParticipar from './components/ComoParticipar';
import sectionImg from '../../assets/fondos/newImagePanorama.jpg'

const Public = ({ setIsAuth }) => {
    const [showAuth, setShowAuth] = useState(false);
    const [invitado, setInvitado] = useState('');
    const [modal, setModal] = useState(false);
    const [registerClicked, setRegisterClicked] = useState(false)

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let foo = params.get('query');
        setInvitado(foo);
    },[]);

    useEffect(() => {
        
    },[registerClicked]);

    return (
        <div className="public-container">
            <Header />
            <div className="home-img-section-container" style={{ backgroundImage: `url(${sectionImg})` }}>
                <span className='section-text1'>¡RECOMIÉNDANOS A TUS AMIGOS!</span>
                <span className='section-text2'>Y GANA $60.000</span>
                <div className='register-btn' onClick={() => setRegisterClicked(true)}>REGÍSTRATE</div>
            </div>
            <div className="home-container">
                
                {window.innerWidth > 992 ? 
                    <div className='home-columns-container'>
                        <div className="home-column-one">
                            <ComoParticipar setShowAuth={setShowAuth} />
                            <ProductoEmpresa />
                        </div>
                        <div className="home-column-two">
                            <Autenticacion registerClicked={registerClicked} setIsAuth={setIsAuth} invitado={invitado} modal={modal} setModal={setModal}/>
                        </div>
                    </div>
                :
                    <div className='home-columns-container'>
                        { showAuth === false ?
                            <div className="home-column-one">
                                <ComoParticipar setShowAuth={setShowAuth} />
                                <ProductoEmpresa />
                            </div>
                            :
                            <div className="home-column-two">
                                <Autenticacion registerClicked={registerClicked} setShowAuth={setShowAuth} setIsAuth={setIsAuth} invitado={invitado} modal={modal} setModal={setModal}/>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
export default Public;