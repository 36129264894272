import React from 'react';
import Boton from '../../../Private/components/Boton';
import './index.scss';

const ComoParticipar = ({ setShowAuth }) => {
    return (
        <div className="container-recom">
            <p className="recom-info">
                ¿CÓMO PARTICIPAR?
            </p>
            <p className="recom-info">
                Por cada recomendación que nos compre un cierre de cristales, tú ganarás $60.000.
            </p>
            <p className="recom-info">
                Refiere sin límites y sé parte del Club Lumon.
            </p>
            <p className="recom-info">
                Además, invita a otros a participar y gana $20.000 por sus referencias.
            </p>

            <Boton text="INGRESAR" nueva=" nueva" fun={() => setShowAuth(true)}/>
        </div>
    );
}
 
export default ComoParticipar;