import React, { Fragment, useState, useEffect } from 'react';
import passImg from '../../../../../../assets/icons/2.png';
import checkVerdeImg from '../../../../../../assets/icons/12.png';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Contraseña = ({ closeModal }) => {

    const [credenciales, setCredenciales] = useState({
        contraseña_actual: "",
        contraseña_nueva: "",
        contraseña_nueva2: "",
    });

    // const [progress, setProgress] = useState(false);
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false);
    const [exito, setExito] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [failedMessage, setFailedMessage] = useState('');


    const { contraseña_actual, contraseña_nueva, contraseña_nueva2 } = credenciales;

    useEffect(() => {
        setAuthToken(localStorage.getItem('auth-token'));
    }, []);

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if  (contraseña_actual.trim() === '' || contraseña_nueva.trim() === '' || contraseña_nueva2.trim() === '') {
            setFailedMessage('Todos los campos son necesarios')
            setFailedLoginSnackbar(true)
            return;
        }

        if  (contraseña_nueva.length < 8) {
            setFailedMessage('La contraseña debe tener al menor 8 caracteres.')
            setFailedLoginSnackbar(true)
            return;

        }
        if  (contraseña_nueva !== contraseña_nueva2) {
            setFailedMessage('Las contraseñas no coinciden.')
            setFailedLoginSnackbar(true)
            return;
        }

        // setProgress(true);

        const data = {
            old_password: contraseña_actual,
            new_password: contraseña_nueva,
        }

        //var url = 'http://127.0.0.1:8000/api/change_password/'
        var url = 'https://referidos-app.herokuapp.com/api/change_password/'
        
        fetch (url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
                "Authorization": `Token ${authToken}`
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.status) {
                // setProgress(false)
                setFailedLoginSnackbar(false)
                setExito(true);
            } 
            else { 
                if (res.old_password){
                    setFailedMessage('La contraseña ingresada no es correcta.')
                }

                // setProgress(false)
                setFailedLoginSnackbar(true)
            }
        })


    };

    return (
        <Fragment>
            <Snackbar 
                open={failedLoginSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error">
                    {failedMessage}
                </Alert>
            </Snackbar>
            
            <p className="ingresar-p"><span className="fontSpace">Cambiar contraseña</span></p>
            { !exito ? 
                <form onSubmit={handleSubmit}>
                    <div className="input-login">
                        <span><img src={passImg} alt="contraseña_actual" className="ubi-img" /></span>
                        <input type="password" name="contraseña_actual" value={contraseña_actual} onChange={e => handleChange(e)} placeholder="Contraseña actual" />
                    </div>
                    <div className="input-login">
                        <span><img src={passImg} alt="contraseña_nueva" className="ubi-img" /></span>
                        <input type="password" name="contraseña_nueva" value={contraseña_nueva} onChange={e => handleChange(e)} placeholder="Contraseña nueva" />
                    </div>
                    <div className="input-login">
                        <span><img src={passImg} alt="contraseña_nueva2" className="ubi-img" /></span>
                        <input type="password" name="contraseña_nueva2" value={contraseña_nueva2} onChange={e => handleChange(e)} placeholder="Confirme contraseña" />
                    </div>

                    <div className="linea-gris" />

                    <p className="presentador-p">En caso de verificar su contraseña actual, <br /> se generará su contraseña nueva.</p>

                    <button className="ingresar"><span className="fontSpace">ACTUALIZAR</span></button>
                </form>
            :
                <Fragment>
                    <div className="exito">
                        <img src={checkVerdeImg} alt="checkverde" className="check-verde"/>
                        <p>Contraseña actualizada</p>
                    </div>

                    <div className="cerrar-container"><button className="cerrar-btn" onClick={closeModal}><span className="fontSpace">CERRAR</span></button></div>
                </Fragment>
            }
        </Fragment>
    );
}
 
export default Contraseña;