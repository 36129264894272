import React, { Fragment, useState } from 'react';
import emailImg from '../../../../../../assets/icons/1.png';
import checkVerdeImg from '../../../../../../assets/icons/12.png';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Email = ({ closeModal }) => {

    const [credenciales, setCredenciales] = useState({
        email: "",
    });

    // const [progress, setProgress] = useState(false)
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false)
    const [exito, setExito] = useState(false);
    // const [authToken, setAuthToken] = useState(localStorage.getItem('auth-token'))
    const [failedMessage, setFailedMessage] = useState('')


    const { email } = credenciales;

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if  (email.trim() === '') {
            setFailedMessage('Debe ingresar su correo electrónico')
            setFailedLoginSnackbar(true)
            return;
        }

        // setProgress(true)

        const data = {
            email: email,
        }

        //var url = 'http://127.0.0.1:8000/api/reset_password/'
        var url = 'https://referidos-app.herokuapp.com/api/reset_password/'
        fetch (url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
            }
        }).then(response => response.json ())
        .then(res => {

            if (res.response) {
                // setProgress(false)
                setFailedLoginSnackbar(false)
                setExito(true);
            } 
            else { 

                setFailedMessage('Correo electrónico inválido.')
                // setProgress(false)
                setFailedLoginSnackbar(true)
            }
        })


    };

    return (
        <Fragment>
            <Snackbar 
                open={failedLoginSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error">
                    {failedMessage}
                </Alert>
            </Snackbar>
            
            <p className="ingresar-p"><span className="fontSpace">Olvidaste tu contraseña</span></p>
            { !exito ? 
                <form onSubmit={handleSubmit}>
                    <div className="input-login">
                        <span><img src={emailImg} alt="email" className="ubi-img" /></span>
                        <input type="text" name="email" value={email} onChange={e => handleChange(e)} placeholder="Correo electrónico" />
                    </div>
                    
                    <p className="presentador-p">En caso de enviar esta solicitud, <br /> se enviará una nueva contraseña a su correo electrónico.</p>

                    <button className="ingresar"><span className="fontSpace">ENVIAR</span></button>
                </form>
            :
                <Fragment>
                    <div className="exito">
                        <img src={checkVerdeImg} alt="checkverde" className="check-verde"/>
                        <p>Se envió contraseña correctamente.</p>
                    </div>

                    <div className="cerrar-container"><button className="cerrar-btn" onClick={closeModal}><span className="fontSpace">CERRAR</span></button></div>
                </Fragment>
            }
        </Fragment>
    );
}
 
export default Email;