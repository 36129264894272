import React from 'react';
import './index.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img2 from '../../../../assets/Carrusel/FOTOS PANORAMA _1.jpg';
import img3 from '../../../../assets/Carrusel/FOTOS PANORAMA _12CC.jpg';
import img4 from '../../../../assets/Carrusel/PHOTO-2020-08-24-19-18-46.jpg';
import img5 from '../../../../assets/Carrusel/Quincho.jpg';
import instagram from '../../../../assets/icons/instagram3.jpg'

import video from '../../../../assets/Videos/INICIO.mp4';
import equis from '../../../../assets/icons/7.png';
import Modal from 'react-modal';

const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
    }
};
   
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

const ProductoEmpresa = () => {
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="ProdEmpr-container">
            <div className="ProdEmpr-innerContainer">
                <div style={{ marginRight: "10px" }}>
                    <p>EL PRODUCTO</p>
                    <div className="linea-amarilla"/>
                    <p className="ProdEmpr-marginBottom">Las cortinas de cristal se instalan en terrazas de departamentos para aislar el frío, el ruido y la lluvia, permitiendo aprovechar este espacio todo el año.</p>
                </div>

                <div>
                    <p>LA EMPRESA</p>
                    <div className="linea-amarilla"/>
                    <p >Lumon, de origen Finlandés, creó el sistema hace 40 años, está presente en 26 países y es la empresa líder del planeta.</p>
                </div>
            </div>

            <div style={{ cursor: "pointer"}} onClick={() => {
                    if (window.innerWidth > 992) {
                        openModal();
                    }
                }}>

                <div style={{ cursor: "pointer", display: "flex", justifyContent:"space-between"}}> 
                    <div className="carousel-movil">
                        <Carousel>
                            <div>
                                <img src={img2} alt="2" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img3} alt="3" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img4} alt="4" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img5} alt="5" className="img-carousel" />
                            </div>
                        </Carousel>
                    </div>
                    <div className="carousel-none">
                        <Carousel>
                            <div>
                                <img src={img3} alt="3" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img4} alt="4" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img5} alt="5" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img2} alt="2" className="img-carousel" />
                            </div>
                        </Carousel>
                    </div>
                    <div className="carousel-none">
                        <Carousel>
                            <div>
                                <img src={img4} alt="4" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img5} alt="5" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img2} alt="2" className="img-carousel" />
                            </div>
                            <div>
                                <img src={img3} alt="3" className="img-carousel" />
                            </div>
                        </Carousel>   
                    </div>
                </div>
            </div>

            <div className='ProdEmpr-footer'>
                <div onClick={() => openInNewTab('https://www.instagram.com/panoramachile/')} className='footer-left'>
                    <span><img src={instagram} alt="instagram" className="instagram-icon" /></span>
                    <p>Panoramachile</p>
                </div>
                <div className='footer-right'>
                    <p>info@panoramachile.cl </p>
                    <p>+56 2 3210 0714 </p>
                    <p>+56 9 3194 98 45</p>
                </div>
                
            </div>


            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="modal-carousel">
                    <img src={equis} alt="equis" className="equis" onClick={() => closeModal()}/>
                    <Carousel>
                        <div>
                            <video height="480" width={"100%"} controls>
                                <source src={video} type="video/mp4"　/>
                                Your browser does not support HTML video.
                            </video>
                        </div>
                        <div>
                            <img src={img2} alt="2" className="img-carousel-modal" />
                        </div>
                        <div>
                            <img src={img3} alt="3" className="img-carousel-modal" />
                        </div>
                        <div>
                            <img src={img4} alt="4" className="img-carousel-modal" />
                        </div>
                        <div>
                            <img src={img5} alt="5" className="img-carousel-modal" />
                        </div>
                    </Carousel>
                </div>
            </Modal>
        </div>
    );
}
 
export default ProductoEmpresa;