import React, { Fragment, useState, useEffect } from 'react';
import icono from '../../../../assets/icons/4.png';
import CircularProgress from '@material-ui/core/CircularProgress';

import './index.scss';

const Tablas = () => {

    const categoriasReferencias = [
        "Fecha",
        "Referido",
        "Teléfono",
        "Email",
        "Progreso",
        "Fecha compra",
        "Premio",
    ];

    const categoriasInvitaciones = [
        "Fecha Inscripción",
        "Nombre",
        "Email",
        "Ventas Realizadas",
        "Referencias Realizadas",
        "Premios Pagados",
        "Premios Por Pagar"
    ];

    // const [authToken, setAuthToken] = useState(localStorage.getItem('auth-token'))
    const [ready, setReady] = useState(false);

    const [personasReferencias, setPersonasReferencias] = useState([])
    const [personasInvitaciones, setPersonasInvitaciones] = useState([])

    const [tabla, setTabla] = useState({
        categorias: categoriasReferencias,
        personas: personasReferencias,
    });

    const { categorias, personas } = tabla;

    const [tabs, setTabs] = useState({
        misReferencias: 'seleccionada',
        misInvitaciones: '',
    });

    const { misReferencias, misInvitaciones } = tabs;


    useEffect(() => {

        setReady(false)
        //var url = 'http://127.0.0.1:8000/api/references/my_references'
        var url = 'https://referidos-app.herokuapp.com/api/references/my_references'
        var state = []
        //var url2 = 'http://127.0.0.1:8000/api/guests/'
        var url2 = 'https://referidos-app.herokuapp.com/api/guests/'
        var state2 = []

        fetch(url, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Token ${localStorage.getItem('auth-token')}`
                }
            }).then(response => response.json())
            .then(res => {
                for (let r in res) {
                    if (res[r].fecha_creacion) {
                        var date = new Date(Date(res[r].fecha_creacion));
                        res[r].fecha_creacion = date.toLocaleDateString()
                        if (res[r].fecha_compra) {
                            date = new Date(Date(res[r].fecha_compra));
                            res[r].fecha_compra = date.toLocaleDateString()
                        }
                        state.push(res[r])
                    }
                    
                }
                setPersonasReferencias(state)
            })

        fetch(url2, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Token ${localStorage.getItem('auth-token')}`
                }
            }).then(response => response.json())
            .then(res => {
                for (let r in res) {
                    if (res[r].fecha_creacion) {
                        var date = new Date(Date(res[r].date_created));
                        res[r].date_created = date.toLocaleDateString()
                        state2.push(res[r])
                    }
                    
                }
                setPersonasInvitaciones(state2)
                
                
            })

        setReady(true);
    }, []);

    return (

        <div className="table-container">
            { ready ?
                <div>
                    <div className="tabs">
                        <p className={misReferencias} onClick={() => {
                            setTabla({ categorias: categoriasReferencias, personas: personasReferencias });
                            setTabs({ misReferencias: 'seleccionada', misInvitaciones: '' });
                        }}><span className="fontSpace">Mis referencias</span></p>
                        <p className={misInvitaciones} onClick={() => {
                            setTabla({ categorias: categoriasInvitaciones, personas: personasInvitaciones });
                            setTabs({ misReferencias: '', misInvitaciones: 'seleccionada' });
                        }}><span className="fontSpace">Mis invitaciones</span></p>
                    </div>
                        
                    { personas.length !== 0 ?
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {categorias.map(category => (
                                            <th scope="col" key={category}><span className="fontSpace">{category}</span></th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {personas.map(persona => (
                                        <tr>
                                            {Object.keys(persona).map(cate => (
                                                persona[cate] === "CONTACTADO" ?
                                                    <td style={{ color: "#CED9E5" }} key={cate}>{persona[cate]}</td>
                                                : (
                                                    persona[cate] === "RECHAZADO" ?
                                                        <td style={{ color: "red" }} key={cate}>{persona[cate]}</td>
                                                    : (
                                                        persona[cate] === "COMPRA REALIZADA" || persona[cate] === "PAGADO" ?
                                                            <td style={{ color: "#253746" }} key={cate}>{persona[cate]}</td>
                                                        : 
                                                            <td key={cate}>{persona[cate]}</td>
                                                    )
                                                )
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    :
                        <div className="no-referencias">
                            {misReferencias === 'seleccionada' ? 
                                    <Fragment>
                                        <img src={icono} alt="icono" className="icono" />
                                        <p>No has realizado <br className="break-p" /> ninguna referencia aún.</p>

                                        <p>¡Presiona <span>NUEVA REFERENCIA</span> <br /> para comenzar a ganar dinero!</p>
                                    </Fragment>
                                :
                                    <Fragment>
                                        <img src={icono} alt="icono" className="icono" />
                                        <p>No has realizado <br  className="break-p" /> ninguna invitación aún.</p>

                                        <p>¡Presiona <span>GENERAR INVITACIÓN</span> <br /> para comenzar a ganar dinero!</p>
                                    </Fragment>
                            }
                        </div>
                    }
                </div>:
                <div style={{ margin: ' 15px auto 0' }} >
                    <CircularProgress color="primary"/>
                </div> 
            }
        </div>
    );
}

export default Tablas;