import React, { Fragment, useState, useEffect } from 'react';
import userImg from '../../../../../../assets/icons/3.png';
import bancoImg from '../../../../../../assets/icons/9.png';
import cuentaImg from '../../../../../../assets/icons/10.png';
import numeroImg from '../../../../../../assets/icons/11.png';
import checkVerdeImg from '../../../../../../assets/icons/12.png';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Datos = ({ closeModal, setModal }) => {

    const [credenciales, setCredenciales] = useState({
        name: "",
        banco: "",
        tipo_cuenta: "",
        numero_cuenta: "",
        rut: "",
    });

    // const [progress, setProgress] = useState(false);
    const [failedLoginSnackbar, setFailedLoginSnackbar] = useState(false);
    const [exito, setExito] = useState(false);
    const [authToken, setAuthToken] = useState("");


    const { name, banco, tipo_cuenta, numero_cuenta, rut } = credenciales;

    useEffect(() => {
        setAuthToken(localStorage.getItem('auth-token'));
        //var url = 'http://127.0.0.1:8000/api/bank_data/'
        var url = 'https://referidos-app.herokuapp.com/api/bank_data/'

        fetch (url, {
            method: "GET",
            headers:{
                'Content-Type': 'application/json',
                "Authorization": `Token ${localStorage.getItem('auth-token')}`
            }
        }).then(response => response.json ())
        .then(res => {
            setCredenciales({
                name: res.nombre_titular_cuenta,
                banco: res.banco,
                tipo_cuenta: res.tipo_cuenta,
                numero_cuenta: res.numero_cuenta,
                rut: res.rut
            })
            
        })    
    },[]);

    const handleChange = e => {
        setCredenciales({
            ...credenciales,
            [e.target.name]: e.target.value
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFailedLoginSnackbar(false);
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (name.trim() === '' || banco.trim() === '' || tipo_cuenta.trim() === '' || numero_cuenta.trim() === '') {
            return;
        }

        // setProgress(true);

        const data = {
            nombre_titular_cuenta: name,
            banco: banco,
            tipo_cuenta: tipo_cuenta,
            numero_cuenta: numero_cuenta,
            rut: rut
        }
        //var url = 'http://127.0.0.1:8000/api/bank_update/'
        var url = 'https://referidos-app.herokuapp.com/api/bank_update/'
        fetch (url, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
                "Authorization": `Token ${authToken}`
            }
        }).then(response => response.json ())
        .then(res => {

            if (res) {
                // setProgress(false);
                setFailedLoginSnackbar(false)
                setExito(true);
            } 
            else { 
                // setProgress(false);
                setFailedLoginSnackbar(true)
            }
        })
    };

    return (
        <Fragment>
            <Snackbar 
                open={failedLoginSnackbar} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error">
                    'No se actualizaron datos'
                </Alert>
            </Snackbar>
            
            <p className="ingresar-p"><span className="fontSpace">Datos bancarios</span></p>
            { !exito ? 
                <form onSubmit={handleSubmit}>
                    <div className="input-login">
                        <span><img src={userImg} alt="name" className="ubi-img" /></span>
                        <input type="text" name="name" value={name} onChange={e => handleChange(e)} placeholder="Titular de la cuenta" />
                    </div>
                    <div className="input-login">
                        <span><img src={bancoImg} alt="banco" className="ubi-img" /></span>
                        <input type="banco" name="banco" value={banco} onChange={e => handleChange(e)} placeholder="Banco" />
                    </div>
                    <div className="input-login">
                        <span><img src={cuentaImg} alt="tipo_cuenta" className="pass-img" /></span>
                        <input type="text" name="tipo_cuenta" value={tipo_cuenta} onChange={e => handleChange(e)} placeholder="Tipo de cuenta" />
                    </div>
                    <div className="input-login">
                        <span><img src={numeroImg} alt="numero_cuenta" className="ubi-img" /></span>
                        <input type="text" name="numero_cuenta" value={numero_cuenta} onChange={e => handleChange(e)} placeholder="Número de cuenta" />
                    </div>
                    <div className="input-login">
                        <span><img src={numeroImg} alt="rut" className="ubi-img" /></span>
                        <input type="text" name="rut" value={rut} onChange={e => handleChange(e)} placeholder="RUT" />
                    </div>

                    <div className="linea-gris" />

                    <p className="presentador-p">En caso de tener una cuenta registrada, <br /> se actualizarán los datos por los recién ingresados.</p>

                    <button className="ingresar"><span className="fontSpace">ACTUALIZAR</span></button>
                </form>
            :
                <Fragment>
                    <div className="exito">
                        <img src={checkVerdeImg} alt="checkverde" className="check-verde"/>
                        <p>Datos actualizados correctamente</p>
                    </div>

                    <div className="cerrar-container">
                        <button className="cerrar-btn" onClick={() => {
                            if (window.innerWidth > 992) {
                                closeModal();
                            } else {
                                setModal(false);
                            }
                        }}>
                            <span className="fontSpace">CERRAR</span>
                        </button>
                    </div>
                </Fragment>
            }
        </Fragment>
    );
}
 
export default Datos;